import * as React from "react";
import { Helmet } from "react-helmet";
import { Link, withPrefix } from 'gatsby';

import MainEffect from "../components/mainEffect";
import "../scss/main.scss";
import { IMG_BASE,FRONT_DOMAIN } from "../config";

import VideoBgMain from "../components/videoBgMain";

const IndexPage = ({pageContext: {indexText, contacts}}) => {
    const [isMobile, setIsMobile] = React.useState();
    const preparedSocialList = contacts.items.reduce((acc, item) => ({
        ...acc,
        [item.name.toLowerCase()]: item
    }), {});
    
    const checkBreakpoints = () => {
        setIsMobile(window.innerWidth < 768);
    }
    
    React.useEffect(() => {
        checkBreakpoints();
        
        window.addEventListener('resize', checkBreakpoints);
        document.body.classList.add("is-done");
        return () => window.removeEventListener('resize', checkBreakpoints);
    }, []);

    const {description} = indexText.items[0];
    const {email, whatsapp, phone, telegram, instagram, facebook, linkedin} = preparedSocialList;

    return (
        <>
            <Helmet>
                <title>Home | Teleport</title>
                <script src={withPrefix('inobounce.js')}></script>
                <meta property="og:image" content={`${FRONT_DOMAIN}/img/share_default.jpg`}/>
            </Helmet>

            <VideoBgMain />

            {/*<Link to="/tickets" className="page-content__past">Leave the past</Link>
            <Link to="/tickets" className="page-content__future">Enter the future</Link>*/}

            <section className="site-section">
                <div className="about-event">
                    <div className="about-event__text" dangerouslySetInnerHTML={{__html: description.replace(/\|/g,isMobile?'<br>':' ')}}>
                    </div>

                    <ul className="socials-list socials-list_horizontal">
                        {instagram &&
                            <li className="socials-list__item">
                                <a href={instagram.url} className="socials-list__link" target="_blank" rel="noreferrer">
                                    <img src={`${IMG_BASE}/${instagram.icon}`} alt="" className="socials-list__icon"/>
                                </a>
                            </li>
                        }
                        {facebook &&  
                            <li className="socials-list__item">
                                <a href={facebook.url} className="socials-list__link" target="_blank" rel="noreferrer">
                                    <img src={`${IMG_BASE}/${facebook.icon}`} alt="" className="socials-list__icon"/>
                                </a>
                            </li>
                        }
                       
                    </ul>
                </div>
            </section>

            {/*<MainEffect />*/}
        </>
    )
}

export default IndexPage;
