import React from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { isMobile } from "react-device-detect";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";

class MainEffect extends React.Component {
    requestAnimationId = null
    position = null

    mouseMoveHandler = (e, position) => {
        const cursorPosition = e.clientX / window.innerWidth;

        if (cursorPosition < 0.33) {
            this.position = "left";
        }

        if (cursorPosition > 0.33 && cursorPosition < 0.66) {
            this.position = "center";
        }

        if (cursorPosition > 0.66) {
            this.position = "right";
        }
    }

    componentDidMount() {
        // Camera FOV
        const frustumSize = 55;

        // Screen aspect ratio
        const aspectRatio = window.innerHeight / window.innerWidth;

        // OrbitControls max rotation
        const maxRotation = 0.5;

        // Setting base rotation speed for all instances
        const baseRotationSpeed = isMobile ? 18 : 36;

        const scene = () => {
            // Basic three js setup
            const scene = new THREE.Scene();
            const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });

            // renderer.domElement.style.opacity = 0;
            this.mount.appendChild(renderer.domElement);
            renderer.setPixelRatio(window.devicePixelRatio);
            
            const height = window.innerWidth > 767 ?  this.mount.getBoundingClientRect().height : window.innerHeight;
            renderer.setSize(
                this.mount.getBoundingClientRect().width,
                height,
            );

            this.position = "center";
            let prevPosition = "center";

            renderer.setClearColor(new THREE.Color(0xffffff));
            renderer.setClearAlpha(0);

            // Camera setup
            const camera = new THREE.OrthographicCamera(
                frustumSize / -2,
                frustumSize / 2,
                (frustumSize * aspectRatio) / 2,
                (frustumSize * aspectRatio) / -2,
                -100,
                1000
            );

            camera.position.z = 1;

            // OrbitControls setup
            const controls = new OrbitControls(camera, renderer.domElement);
            
            controls.autoRotate = true;
            controls.autoRotateSpeed = isMobile ? baseRotationSpeed : 0;
            controls.minPolarAngle = Math.PI * maxRotation;
            controls.maxPolarAngle = Math.PI * maxRotation;
            controls.minAzimuthAngle = Math.PI * -maxRotation;
            controls.maxAzimuthAngle = Math.PI * maxRotation;
            controls.enableZoom = false;
            controls.enablePan = false;
            controls.enableRotate = isMobile ? true : false;
            controls.update();

            // Materials setup
            const material = new THREE.MeshStandardMaterial({
                color: "#ffffff",
                emissive: "#ffffff",
                emissiveIntensity: 0.6,
                metalness: 0,
                roughness: 0.2,
                // reflectivity: 1,
            });

            // Handling cursor hover over zones
            document.addEventListener("mousemove", this.mouseMoveHandler);

            // Loading GLTF model
            const loader = new GLTFLoader();

            loader.load("/static/models/teleport.gltf", (gltf) => {
                const changeObjectScale = (scale) => {
                    gltf.scene.scale.x = scale;
                    gltf.scene.scale.y = scale;
                    gltf.scene.scale.z = scale;
                };

                gltf.scene.position.x = 0;
                gltf.scene.position.y = 1.5;

                if (isMobile) {
                    changeObjectScale(1.4);
                } else {
                    changeObjectScale(0.65);
                }

                gltf.scene.children[0].children.forEach((o) => {
                    o.material = material;
                });

                scene.add(gltf.scene);

                document.body.classList.add("is-done");
            });

            // Light setup
            const hemiLight = new THREE.HemisphereLight(0xffffbb, 0x080820, 0.5);
            const spotLightRight = new THREE.PointLight("pink", 1, 100);

            spotLightRight.position.y = 15;
            scene.add(hemiLight);
            scene.add(spotLightRight);

            // Postprocessing setup
            const composer = new EffectComposer(renderer);
            const pass = new RenderPass(scene, camera);
            const bloom = new UnrealBloomPass(
                new THREE.Vector2(window.innerWidth, window.innerHeight),
                1.5,
                0.4,
                0.85
            );

            bloom.strength = 1.7;
            bloom.radius = 0.9;
            composer.addPass(pass);
            composer.addPass(bloom);

            // window.addEventListener("resize", () => {
            //     camera.right = frustumSize / -2;
            //     camera.left = frustumSize / 2;
            //     camera.top = (frustumSize * aspectRatio) / 2;
            //     camera.bottom = (frustumSize * aspectRatio) / -2;
            //     camera.updateProjectionMatrix();
            //     renderer.setSize(window.innerWidth, window.innerHeight);
            // });

            const self = this;

            const animate = function () {
                self.requestAnimationId = requestAnimationFrame(animate);
                // Mobile screen animation
                let minimalSpeedCoefficient = 0;

                if (isMobile) {
                    controls.enableRotate = true;

                    if (Math.abs(controls.getAzimuthalAngle() / Math.PI) === 0.5) {
                        const isPositive = controls.autoRotateSpeed > 0;
                        controls.autoRotateSpeed = isPositive
                        ? -baseRotationSpeed
                        : baseRotationSpeed;
                    }

                    minimalSpeedCoefficient = 0.4;
                }

                if (!isMobile) {
                    if (
                        Math.abs(controls.getAzimuthalAngle()) < 0.001 &&
                        self.position === "center"
                    ) {
                        controls.autoRotate = false;
                    }

                    if (prevPosition !== self.position) {
                        controls.autoRotate = true;
                        switch (self.position) {
                            case "right":
                                controls.autoRotateSpeed = baseRotationSpeed;
                            break;
                                case "left":
                                    controls.autoRotateSpeed = -baseRotationSpeed;
                            break;
                                case "center":
                                    if (controls.getAzimuthalAngle() > 0.001) {
                                        controls.autoRotateSpeed = Math.abs(controls.autoRotateSpeed);
                                    }

                                    if (controls.getAzimuthalAngle() < 0) {
                                        controls.autoRotateSpeed = -Math.abs(controls.autoRotateSpeed);
                                    }
                            break;
                            default:
                            break;
                        }

                        prevPosition = self.position;
                    }

                    minimalSpeedCoefficient = 0.6;
                }

                // Dampening
                const dampeningCoefficient = 1 - Math.abs(Math.abs(controls.getAzimuthalAngle() / Math.PI) - 0.25) * 4;
                const isPositive = controls.autoRotateSpeed > 0 ? 1 : -1;
                const minimalSpeed = isPositive * minimalSpeedCoefficient;
                controls.autoRotateSpeed = baseRotationSpeed * dampeningCoefficient * isPositive + minimalSpeed;

                controls.update();
                renderer.render(scene, camera);
                composer.render();
            };

            animate();
        };

        scene();
    }

    componentWillUnmount() {
        document.removeEventListener("mousemove", this.mouseMoveHandler);
        cancelAnimationFrame(this.requestAnimationId);
    }

    render() {
        return (
            <div ref={ ref => (this.mount = ref) } id="appContainer" className="scene">
            </div>
        )
    }
}

export default MainEffect;
