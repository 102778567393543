import * as React from "react";

const VideoBgMain = () => {
    // const [ videoShowed, setCanAddSrc ] = React.useState(false);
    const videoEl = React.useRef(null);

    /*React.useEffect(() => {
        // Чтобы не было белого фона при загрузке видео
        // setCanAddSrc(true);
        //videoEl.current.playbackRate = 0.35;
    }, []);*/

    return (
        <div className="site-wrapper__video-wrap main-page-video-wrap">
            <video
                className="site-wrapper__video main-page-video is-visible"
                src="/static/video/background_video.mp4"
                ref={ videoEl }
                autoPlay muted playsInline loop
            />
        </div>
    )
}

export default VideoBgMain;